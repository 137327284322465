<template>

</template>

<script>
let apiUrl = ''

export default {
  name: "Common",
  apiUrl,
}
</script>

<style scoped>

</style>