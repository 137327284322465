import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import VueResource from 'vue-resource'

import global from './main/Common'
Vue.prototype.COMMON = global

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(VueResource)
Vue.prototype.$log = console.log.bind(console)

Vue.http.interceptors.push((request,next) =>{
  //if (sessionStorage.getItem("accessToken")){
    //Vue.http.headers.common['Authorization'] = "Bearer " + sessionStorage.getItem("accessToken")
    //request.headers['Authorization'] = sessionStorage.getItem("accessToken")
  //}
  next((response) => {
    if (response.status == 401) {
      sessionStorage.removeItem("accessToken")
      router.push({path:'/login'})
    }
  })
})

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  },
  created:function () {
    this.$http.get('conf.json').then(response => {
      Vue.prototype.COMMON.apiUrl = response.data['apiUrl'];
    })
  }
})
