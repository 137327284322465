import Vue from 'vue'
import Router from 'vue-router'


// Views
const Login = () => import('@/main/Login')
const Register = () => import('@/main/Register')
const Dashboard = () => import('@/main/Dashboard')
const ChangePassword = () => import('@/main/ChangePassword')
const Logout = () => import('@/main/Logout')
const Fish = () => import('@/main/Fish')
const User = () => import('@/main/User')
const Twofactor = () => import('@/main/Twofactor')

// Containers
const TheContainer = () => import('@/containers/TheContainer')


Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to,from,next) => {
  let token = sessionStorage.getItem("accessToken")
  if (to.matched.some(record => record.meta.requireAuth) && (token == null || !token)){
    next({path:'/login'})
  }else {
    next()
  }

})

export default router


/*export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})*/

function configRoutes () {
  return [
    {
      path: '/login',
      name: 'Login',
      meta: {
        requireAuth: false
      },
      component:Login
    },
    {
      path: '/register',
      name: 'Register',
      meta: {
        requireAuth: false
      },
      component:Register
    },
    {
      path: '/',
      redirect: '/dashboard',
      name: '首页',
      meta: {
        requireAuth: true
      },
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'fish',
          name: '鱼苗管理',
          meta: {
            requireAuth: true
          },
          component: Fish
        },
        {
          path: 'twofactor',
          name: '二次验证',
          meta: {
            requireAuth: true
          },
          component:Twofactor
        },
        {
          path: 'password',
          name: '修改密码',
          meta: {
            requireAuth: true
          },
          component:ChangePassword
        },
        {
          path: 'user',
          name: '用户管理',
          meta: {
            requireAuth: true
          },
          component:User
        },
        {
          path: 'logout',
          name: 'Logout',
          meta: {
            requireAuth: true
          },
          component: Logout
        }
      ]
    }
  ]
}

